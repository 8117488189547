import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../api';

export const createMyApps = createAsyncThunk(
  'app/create',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.post('/api/v1/myapp', [formValue]);
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);
export const updateMyApps = createAsyncThunk(
  'app/update',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.put('/api/v1/myapp', [formValue]);
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);
export const patchMyApps = createAsyncThunk(
  'app/patch',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.patch('/api/v1/myapp', [formValue]);
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);

export const deleteMyApps = createAsyncThunk(
  'app/delete',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.delete('/api/v1/myapp', { data: [formValue] });
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);

export const findMyApps = createAsyncThunk(
  'app/create',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.get(
        '/api/v1/myapp?order[]=id&order_direction[]=desc'
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);

const myAppSlice = createSlice({
  name: 'myApp',
  initialState: {
    myapps: [],
    error: '',
    loading: false
  },
  extraReducers: {
    [findMyApps.pending]: state => {
      state.loading = true;
    },
    [findMyApps.pending]: state => {
      state.loading = true;
    },
    [findMyApps.fulfilled]: (state, action) => {
      state.loading = false;

      if (!action?.payload?.data?.length) {
        return;
      }

      state.myapps = action?.payload?.data || [];
    },
    [findMyApps.rejected]: (state, action) => {
      state.loading = false;
      if (action?.payload?.message) {
        state.error = action?.payload.message;
      } else {
        state.error = 'Системная ошибка';
      }
    },
    [createMyApps.rejected]: (state, action) => {
      state.loading = false;
      if (action?.payload?.message) {
        state.error = action?.payload.message;
      } else {
        state.error = 'Системная ошибка';
      }
    },
    [updateMyApps.rejected]: (state, action) => {
      state.loading = false;
      if (action?.payload?.message) {
        state.error = action?.payload.message;
      } else {
        state.error = 'Системная ошибка';
      }
    }
  }
});

export default myAppSlice.reducer;
