import React, { useContext, useEffect } from 'react';

import { Navigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
//import Goga from 'components/dashboards/goga';
// import Id from 'components/dashboards/id';
// import Dashboard from 'components/dashboards/default';
import AppContext from 'context/Context';

import AuthSimpleLayout from './AuthSimpleLayout';
import Profile from 'components/pages/user/profile/Profile';
import Settings from 'components/pages/user/settings/Settings';
import Error404 from 'components/errors/Error404';
// import Error500 from 'components/errors/Error500';
import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleRegistration from 'components/authentication/simple/Registration';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import VerifyMail from '../components/authentication/simple/VerifyMail';
import NewPasswordContent from '../components/authentication/NewPasswordContent';
import MyAppDetails from '../components/dashboards/id/MyAppDetails';
import MyApps from '../components/dashboards/id/MyApps';

const Layout = () => {
  let isAuthorized = useSelector(state => state.auth.isAuthorized);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  useEffect(() => {
    setTimeout(() => {
      console.log('once');
    }, 3000);
  });

  if (!isAuthorized) {
    return (
      <>
        <Routes>
          <Route path="errors/404" element={<Error404 />} />
          {/*- ------------- simple ---------------------------  */}
          <Route element={<AuthSimpleLayout />}>
            <Route path="" element={<SimpleLogin />} />
            <Route path="/" element={<SimpleLogin />} />
            <Route path="authentication/login" element={<SimpleLogin />} />
            <Route
              path="authentication/register"
              element={<SimpleRegistration />}
            />
            <Route
              path="authentication/simple/logout"
              element={<SimpleLogout />}
            />
            <Route
              path="authentication/forgot-password"
              element={<SimpleForgetPassword />}
            />
            <Route
              path="authentication/simple/reset-password"
              element={<SimplePasswordReset />}
            />
            <Route
              path="authentication/confirm-mail"
              element={<SimpleConfirmMail />}
            />
            <Route path="authentication/verify" element={<VerifyMail />} />
            <Route
              path="authentication/new-password"
              element={<NewPasswordContent />}
            />
            <Route
              path="authentication/simple/lock-screen"
              element={<SimpleLockScreen />}
            />
          </Route>

          <Route path="/*" element={<Navigate to="/errors/404" replace />} />
        </Routes>
        <ToastContainer
          closeButton={CloseButton}
          icon={false}
          position={toast.POSITION.BOTTOM_LEFT}
        />
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route path="errors/404" element={<Error404 />} />

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/apps" element={<MyApps />} />
          <Route path="/apps/details/:appId" element={<MyAppDetails />} />
          {/*<Route path="dashboard" element={<Goga />} />*/}
          <Route path="/" element={<Navigate to="/apps" replace />} />
          <Route path="user/profile" element={<Profile />} />
          <Route path="user/settings" element={<Settings />} />
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="/*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;

const Decrement = props => {
  return (
    <button aria-label="Decrement value" onClick={props.dec}>
      Decrement
    </button>
  );
};

const Increment = props => {
  return (
    <button aria-label="Increment value" onClick={props.inc}>
      Increment
    </button>
  );
};

Decrement.propTypes = {
  dec: PropTypes.any
};

Increment.propTypes = {
  inc: PropTypes.any
};
