// import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../features/auth/authSlice';
import { errorCodes } from '../../config';

const LoginForm = ({ hasLabel }) => {
  let isLoading = useSelector(state => state.auth.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false,
    updater: 0,
    validated: false,
    emailErrorMessage: '',
    isShowRepeatEmailVerify: false
  });

  const authUser = () => {
    return dispatch(
      login({
        email: formData.email,
        password: formData.password
      })
    );
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    setFormData({
      ...formData,
      ['validated']: true
    });

    setFormData({
      ...formData,
      emailErrorMessage: ''
    });

    if (form.checkValidity() === true) {
      let emailErrors = '';
      let needVerify = false;

      authUser().then(answer => {
        if (answer?.payload?.error !== null) {
          toast.error(`Ошибка: ${answer?.payload?.error}`, {
            theme: 'colored'
          });

          if (answer?.payload?.model_errors?.length > 0) {
            answer?.payload.model_errors[0].forEach(err => {
              switch (err?.Attr) {
                case 'email':
                  emailErrors += ' ' + err.Error;

                  if (
                    err?.Code === errorCodes.ErrorCodeInvalidEmailVerification
                  ) {
                    needVerify = true;
                  }

                  break;
                case 'password':
                  break;
              }
            });
          }
        } else {
          toast.success(`Вы вошли как ${formData.email}`, {
            theme: 'colored'
          });
        }

        setFormData({
          ...formData,
          emailErrorMessage: emailErrors,
          isShowRepeatEmailVerify: needVerify
        });

        if (
          answer?.payload?.data?.length &&
          !answer?.payload?.data[0]?.use_reload &&
          answer?.payload?.data[0].redirect_to
        ) {
          navigate(answer?.payload?.data[0].redirect_to);
        }
      });
    }
  };

  const gotoConfirm = () => {
    setTimeout(() => {
      navigate('/authentication/confirm-mail', {
        state: { email: formData.email, repeatVerify: true }
      });
    });
  };
  const gotoVerify = () => {
    setTimeout(() => {
      navigate(
        '/authentication/verify' +
          `?email=${encodeURIComponent(formData.email)}`
      );
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <InputGroup className="mb-3" hasValidation>
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
          required
          isInvalid={
            (!formData.email && formData.validated) ||
            formData.emailErrorMessage
          }
        />
        <Form.Control.Feedback type="invalid">
          {formData.email === '' && 'Пожалуйста укажите email'}
          {formData.emailErrorMessage}
          {formData.isShowRepeatEmailVerify && (
            <div>
              <Link className="fs--1 mb-0" onClick={gotoConfirm}>
                Отправить подтверждение повторно
              </Link>
              . Если у вас уже есть код,
              <Link className="fs--1 mb-0" onClick={gotoVerify}>
                введите его
              </Link>
            </div>
          )}
        </Form.Control.Feedback>
      </InputGroup>

      <InputGroup className="mb-3" hasValidation>
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Пароль' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
          required
          isInvalid={!formData.password && formData.validated}
        />
        <Form.Control.Feedback type="invalid">
          {formData.password === '' && 'Пожалуйста укажите пароль'}
        </Form.Control.Feedback>
      </InputGroup>

      <Row className="justify-content-between align-items-center">
        {/*<Col xs="auto">*/}
        {/*  <Form.Check type="checkbox" id="rememberMe" className="mb-0">*/}
        {/*    <Form.Check.Input*/}
        {/*      type="checkbox"*/}
        {/*      name="remember"*/}
        {/*      checked={formData.remember}*/}
        {/*      onChange={e =>*/}
        {/*        setFormData({*/}
        {/*          ...formData,*/}
        {/*          remember: e.target.checked*/}
        {/*        })*/}
        {/*      }*/}
        {/*    />*/}
        {/*    <Form.Check.Label className="mb-0 text-700">*/}
        {/*      Запомнить меня*/}
        {/*    </Form.Check.Label>*/}
        {/*  </Form.Check>*/}
        {/*</Col>*/}

        <Col xs="auto">
          <Link className="fs--1 mb-0" to={`/authentication/forgot-password`}>
            Забыли пароль?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={isLoading}
        >
          Вход
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
