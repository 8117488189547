import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { newPassword } from '../../features/user/userSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import NewPasswordFields from './NewPasswordFields';
import Flex from '../common/Flex';
import { Link, useNavigate } from 'react-router-dom';

const NewPasswordForm = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code') || '';
  const email = queryParams.get('email') || '';

  // State
  const [state, setState] = useState({
    code: code,
    email: email,
    codeErrorMessage: '',
    password: ''
  });

  const changePass = () => {
    return dispatch(
      newPassword({
        code: state.code,
        email: state.email,
        password: state.password
      })
    );
  };

  const handleFieldChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const handlePassword = pass => {
    setState({
      ...state,
      password: pass
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    setState({
      ...state,
      codeErrorMessage: ''
    });

    if (state?.password.length < 6) {
      return;
    }

    if (form.checkValidity() === true) {
      changePass().then(data => {
        if (!data?.payload || data?.payload?.error) {
          toast.error(data?.payload?.error || 'Ошибка смены пароля');
        } else {
          toast.success('Пароль изменен');
          // уходим на главную для авторизации
          navigator('/');
        }
      });
    }
  };

  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Установить новый пароль</h5>
        <p className="fs--1 text-600 mb-0">
          или <Link to="/">Войти</Link>
        </p>
      </Flex>
      <Form onSubmit={handleSubmit} noValidate autoComplete="off">
        <InputGroup className="mb-3" hasValidation>
          <Form.Control
            style={{ textAlign: 'center' }}
            placeholder="Код подтверждения"
            value={state.code}
            name="code"
            onChange={handleFieldChange}
            type="text"
            autoComplete="none"
            required
            isInvalid={!state.code || state.codeErrorMessage}
          />
          <Form.Control.Feedback type="invalid">
            {state.code === '' && 'Пожалуйста укажите код подтверждения'}
            {state.codeErrorMessage}
          </Form.Control.Feedback>
        </InputGroup>

        <InputGroup className="mb-3" hasValidation>
          <Form.Control
            style={{ textAlign: 'center' }}
            placeholder="Email"
            value={state.email}
            name="email"
            onChange={handleFieldChange}
            type="text"
            autoComplete="none"
            required
            isInvalid={!state.email || state.codeErrorMessage}
          />
          <Form.Control.Feedback type="invalid">
            {state.email === '' && 'Пожалуйста укажите email'}
          </Form.Control.Feedback>
        </InputGroup>

        <NewPasswordFields resultCallback={handlePassword} />

        <Form.Group>
          <Button type="submit" color="primary" className="mt-3 w-100">
            Сменить пароль
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

NewPasswordForm.propTypes = {
  resultCallback: PropTypes.func
};

NewPasswordForm.defaultProps = {};
export default NewPasswordForm;
