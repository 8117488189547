import React from 'react';
import { useLocation } from 'react-router-dom';
import ConfirmMailContent from 'components/authentication/ConfirmMailContent';

const ConfirmMail = () => {
  const location = useLocation();

  return (
    <div className="text-center">
      <ConfirmMailContent
        email={location.state.email || ''}
        repeatVerify={location.state.repeatVerify || false}
      />
    </div>
  );
};

export default ConfirmMail;
