import React, { useState } from 'react';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewPasswordForm from './NewPasswordForm';

const NewPasswordContent = () => {
  // State
  const [state, setState] = useState({
    isPasswordChanged: false
  });

  const handleIsChanged = e => {
    setState({
      ...state,
      isPasswordChanged: e
    });
  };

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={envelope}
        alt="sent"
        width={100}
      />

      {!state.isPasswordChanged && (
        <NewPasswordForm resultCallback={handleIsChanged} />
      )}

      {state.isPasswordChanged && (
        <>
          <h3>Спасибо, новый пароль установлен</h3>
          <Button as={Link} color="primary" size="sm" className="mt-3" to={`/`}>
            <FontAwesomeIcon
              icon="chevron-left"
              transform="shrink-4 down-1"
              className="me-1"
            />
            На страницу входа
          </Button>
        </>
      )}
    </>
  );
};

export default NewPasswordContent;
