import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import UserActivityChart from './UserActivityChart';
import DynamicForm from './DynamicForm';
import IconButton from '../../common/IconButton';
import {
  findMyHosts,
  updateMyHosts,
  createMyHosts,
  deleteMyHosts
} from '../../../features/app/hostSlice';
import { useDispatch } from 'react-redux';
import MyHost from './MyHost';
import { findMyApps } from '../../../features/app/appSlice';
import { toast } from 'react-toastify';
import ModalPrompt from '../../modal/ModalPrompt';

export const userActivities = [
  ['Активность пользователей', 'Визит', 'Авторизация', 'Регистрация'],
  ['Янв', 43, 85, 28],
  ['Фев', 83, 73, 11],
  ['Мар', 86, 62, 1],
  ['Апр', 72, 53, 200],
  ['Май', 80, 50, 5],
  ['Июн', 50, 70, 4],
  ['Июл', 80, 90, 6],
  ['Авг', 43, 85, 4],
  ['Сент', 83, 73, 5],
  ['Окт', 86, 62, 8],
  ['Ноя', 72, 53, 12],
  ['Дек', 80, 50, 23]
];

const MyAppDetails = () => {
  const { appId } = useParams();
  const [isShowHostForm, setIsShowHostForm] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [app, setApp] = useState({});
  const [editedHost, setEditedHost] = useState({});
  const [isShowPrompt, setIsShowPrompt] = useState(false);

  const dispatch = useDispatch();

  const addHost = () => {
    setEditedHost({});
    setIsShowHostForm(true);
  };

  const hideForm = () => {
    setIsShowHostForm(false);
  };

  const loadAppHosts = () => {
    return dispatch(findMyHosts({ application_id: appId })).then(data => {
      setHosts(data?.payload?.data || []);
    });
  };

  const removeAppHosts = host => {
    return dispatch(deleteMyHosts(host)).then(data => {
      if (data?.error) {
        toast.error(`Ошибка удаления`, {
          theme: 'colored'
        });
      } else {
        toast.success(`Хост удален`, {
          theme: 'colored'
        });
        hideForm();
        setEditedHost({});
        loadAppHosts();
      }
    });
  };

  const saveAppHost = host => {
    if (host?.id > 0) {
      return dispatch(updateMyHosts(host)).then(data => {
        if (data?.error) {
          toast.error(`Ошибка обновления`, {
            theme: 'colored'
          });
        } else {
          toast.success(`Хост обновлен`, {
            theme: 'colored'
          });
          hideForm();
          setEditedHost({});
          loadAppHosts();
        }
      });
    } else {
      host.application_id = appId * 1;

      return dispatch(createMyHosts(host)).then(data => {
        if (data?.error) {
          toast.error(`Ошибка: ` + data?.payload?.error, {
            theme: 'colored'
          });
        } else {
          toast.success(`Хост добавлен`, {
            theme: 'colored'
          });
          hideForm();
          setEditedHost({});
          loadAppHosts();
        }
      });
    }
  };

  const loadApps = () => {
    return dispatch(findMyApps({ ids: [appId] })).then(data => {
      setApp(data?.payload?.data[0] || {});
    });
  };

  useEffect(() => {
    loadAppHosts();
    loadApps();
  }, []);

  const formDataConfig = {
    host: {
      type: 'text',
      label: 'Адрес хоста',
      required: true,
      minLength: 2,
      errorMessage: 'Укажите адрес хоста, например https://somehost.com/'
    },
    auth_suc_redirect: {
      type: 'textarea',
      label: 'Адрес переадресации успешной авторизации',
      required: true,
      minLength: 1,
      errorMessage: 'Укажите описание приложение'
    },
    is_active: {
      type: 'checkbox',
      label: 'Хост активен и доступен'
    },
    use_reload: {
      type: 'checkbox',
      label: 'Использовать location.href',
      description:
        'Установите, если страница требует редирект на другой домен или обновление страницы'
    },
    is_token_in_get: {
      type: 'checkbox',
      label: 'Передавать token в GET запросе'
    }
  };

  const errors = [
    { attr: 'title', error: 'Поле должно быть заполнено', code: 720 }
  ];

  const handleEditHost = host => {
    setEditedHost(host);
    setIsShowHostForm(true);
  };

  const handleDeleteHost = host => {
    setEditedHost(host);
    setIsShowPrompt(true);
  };

  const yesPromptHandler = () => {
    removeAppHosts(editedHost);
    setIsShowPrompt(false);
  };

  const noPromptHandler = () => {
    setIsShowPrompt(false);
    setEditedHost({});
  };

  const handleActivateHost = host => {
    host.is_active = true;
    saveAppHost(host);
  };

  const handleDisableHost = host => {
    host.is_active = false;
    saveAppHost(host);
  };

  return (
    <>
      <h1 className="mb-3">{app.title}</h1>
      <UserActivityChart
        data={userActivities}
        title="Активность пользователей"
        className="h-100"
      />

      <Card className="shadow-none shadow-show-xl">
        <Card.Header className="d-flex flex-between-center bg-light">
          <h5 className="mb-0">Авторизованные хосты</h5>
          <IconButton
            disabled={isShowHostForm}
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
            onClick={addHost}
          >
            <span>Добавить</span>
          </IconButton>
        </Card.Header>
        <Card.Body className="contact-details-todo-list scrollbar h-100">
          {hosts.length > 0 &&
            hosts.map((host, index) => (
              <MyHost
                key={host.id}
                host={host}
                onEdit={handleEditHost}
                onDelete={handleDeleteHost}
                onActivate={handleActivateHost}
                onDisable={handleDisableHost}
                isLast={index === hosts.length - 1}
              />
            ))}

          <ModalPrompt
            yesVariant={'danger'}
            title={'Удалить хост?'}
            isShow={isShowPrompt}
            yesAction={yesPromptHandler}
            noAction={noPromptHandler}
          >
            <p>
              Удаление хоста приведет к невозможности авторизации пользователей,
              использующих адрес <strong>{editedHost?.host}</strong>. Операцию
              удаления невозможно отменить. Вы действительно хотите удалить хост
              ?
            </p>
          </ModalPrompt>
          <DynamicForm
            isShow={isShowHostForm}
            onHide={hideForm}
            onSave={saveAppHost}
            title={editedHost?.id > 0 ? 'Изменить хост' : 'Добавить хост'}
            formDataConfig={formDataConfig}
            initialData={editedHost}
            errors={errors}
          />
        </Card.Body>
        <Card.Footer className="border-top border-200 text-xl-center p-0">
          <div className={'py-x1 py-xl-2'}>&nbsp;</div>
        </Card.Footer>
      </Card>
    </>
  );
};

export default MyAppDetails;
