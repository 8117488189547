import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { restore } from '../../features/auth/authSlice';
import { useDispatch } from 'react-redux';

const ForgetPasswordForm = () => {
  // State
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    if (email) {
      dispatch(
        restore({
          email: email
        })
      ).then(() => {
        toast.success(
          `Вы указали email ${email}. Если пользователь с таким email у нас зарегистрирован, мы отправим ссылку на восстановление.`,
          {
            theme: 'colored'
          }
        );
      });
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Отправить ссылку для сброса
        </Button>
      </Form.Group>

      <Link className="fs--1 text-600" to="/">
        Вернуться на главную страницу
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
