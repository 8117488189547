import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api';

export const register = createAsyncThunk(
  'user',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.post('/api/v1/users', [formValue]);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkVerification = createAsyncThunk(
  'checkVerification',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.post('/api/v1/usersCheckVerification', [
        formValue
      ]);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendEmailVerification = createAsyncThunk(
  'sendEmailVerification',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.post('/api/v1/usersSendVerification', [
        formValue
      ]);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const newPassword = createAsyncThunk(
  'resetPassword',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.post('/api/v1/new-password', [formValue]);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    error: '',
    loading: false
  },

  extraReducers: {
    [register.pending]: state => {
      state.loading = true;
    },
    [register.fulfilled]: (state, action) => {
      state.loading = false;
      localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
      state.user = action.payload;
    },
    [register.rejected]: (state, action) => {
      state.loading = false;
      if (action?.payload?.message) {
        state.error = action?.payload.message;
      } else {
        state.error = 'Системная ошибка';
      }
    }
  }
});

export default userSlice.reducer;
