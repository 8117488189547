import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../api';

export const login = createAsyncThunk(
  'auth/login',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.post('/api/v1/auth', [formValue]);
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);

export const restore = createAsyncThunk(
  'auth/restore',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.post('/api/v1/restore', [formValue]);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    error: '',
    loading: false,
    isAuthorized: false
  },
  extraReducers: {
    [login.pending]: state => {
      state.loading = true;
    },
    [restore.pending]: state => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      localStorage.setItem('profile', JSON.stringify({ ...action.payload }));

      if (!action?.payload?.data?.length) {
        return;
      }

      if (
        action?.payload?.data[0]?.use_reload &&
        action?.payload?.data[0]?.redirect_to
      ) {
        window.location.href = action?.payload?.data[0]?.redirect_to;
        return;
      }

      if (action?.payload?.data[0]?.access_token) {
        state.isAuthorized = true;
      }

      state.token = action?.payload?.data[0];
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      if (action?.payload?.message) {
        state.error = action?.payload.message;
      } else {
        state.error = 'Системная ошибка';
      }
    },
    [restore.fulfilled]: state => {
      state.loading = false;
    },
    [restore.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    ['logout']: state => {
      state.isAuthorized = false;
      state.user = null;
      state.token = null;
    }
    // [register.pending]: state => {
    //   state.loading = true;
    // },
    // [register.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
    //   state.user = action.payload;
    // },
    // [register.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload.message;
    // }
  }
});

export default authSlice.reducer;
