export const version = '1.2.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: true,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '₽',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent'
};

export const errorCodes = {
  ErrorCodeInvalidField: 700,
  ErrorCodeRequiredEmpty: 720,
  ErrorCodeRequiredNotSupported: 729,
  ErrorCodeInvalidEmail: 730,
  ErrorCodeInvalidEmailVerification: 731,
  ErrorCodeSystemError: 750
};

export default {
  version,
  navbarBreakPoint,
  topNavbarBreakpoint,
  settings,
  errorCodes
};
