import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/user/userSlice';
import myAppReducer from '../features/app/appSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    myApp: myAppReducer
  }
});
