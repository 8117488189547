import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DynamicForm = ({
  isShow,
  onHide,
  onSave,
  title,
  formDataConfig,
  initialData,
  errors
}) => {
  const [formData, setFormData] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData, isShow]);

  useEffect(() => {
    errors.forEach(item => {
      setFieldErrors(prevErrors => ({
        ...prevErrors,
        [item.attr]: item.error
      }));
    });
  }, [errors]);

  const handleFieldChange = e => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setFormData({
      ...formData,
      [name]: fieldValue
    });

    setTouchedFields({
      ...touchedFields,
      [name]: true
    });

    setFieldErrors({
      ...fieldErrors,
      [name]: validateField(name, fieldValue)
    });
  };

  const validateField = (name, value) => {
    const fieldConfig = formDataConfig[name];
    if (
      fieldConfig.required &&
      (!value || value.length < fieldConfig.minLength)
    ) {
      return fieldConfig.errorMessage || 'Нужно заполнить';
    }
    return '';
  };

  const isValidForm = () => {
    return Object.keys(formDataConfig).every(
      field => !validateField(field, formData[field])
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    const newTouchedFields = {};
    const newFieldErrors = {};

    Object.keys(formDataConfig).forEach(field => {
      newTouchedFields[field] = true;
      newFieldErrors[field] = validateField(field, formData[field]);
    });

    setTouchedFields(newTouchedFields);
    setFieldErrors(newFieldErrors);

    if (isValidForm()) {
      onSave(formData);
    }
  };

  const renderField = (name, config) => {
    switch (config.type) {
      case 'text':
        return (
          <Form.Group controlId={name} key={name}>
            <Form.Label>{config.label}</Form.Label>
            <Form.Control
              type="text"
              name={name}
              value={formData[name] || ''}
              onChange={handleFieldChange}
              isInvalid={touchedFields[name] && !!fieldErrors[name]}
              required={config.required}
            />
            <Form.Control.Feedback type="invalid">
              {fieldErrors[name]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      case 'textarea':
        return (
          <Form.Group controlId={name} key={name}>
            <Form.Label>{config.label}</Form.Label>
            <Form.Control
              as="textarea"
              name={name}
              rows={config.rows || 3}
              value={formData[name] || ''}
              onChange={handleFieldChange}
              isInvalid={touchedFields[name] && !!fieldErrors[name]}
              required={config.required}
            />
            <Form.Control.Feedback type="invalid">
              {fieldErrors[name]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      case 'checkbox':
        return (
          <Form.Group controlId={name} key={name}>
            <Form.Check
              type="checkbox"
              name={name}
              label={config.label}
              checked={formData[name] || false}
              onChange={handleFieldChange}
              className={'mb-0'}
            />
            {config.description && (
              <p className={'fs--1'}>{config.description}</p>
            )}
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Offcanvas show={isShow} onHide={onHide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="g-3 mb-3">
              {Object.keys(formDataConfig).map(fieldName => (
                <Col xs={12} key={fieldName}>
                  {renderField(fieldName, formDataConfig[fieldName])}
                </Col>
              ))}
            </Row>
            <Row>
              <Col>
                <Button variant="success" type="submit" className="me-2 mb-1">
                  Сохранить
                </Button>
                <Button
                  variant="secondary"
                  className="me-2 mb-1"
                  onClick={onHide}
                >
                  Отмена
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

DynamicForm.propTypes = {
  /**
   * Заголовок в окне формы
   */
  title: PropTypes.string,
  /**
   * Флаг, определяющий показывать модальное окно или нет
   */
  isShow: PropTypes.bool,
  /**
   * Событие, которые будет вызвано,
   * когда форма закрывается
   */
  onHide: PropTypes.func,
  /**
   * Событие, вызываемое при попытке успешного сохранения
   * валидной формы
   */
  onSave: PropTypes.func,
  /**
   * Объект конфигурации полей формы
   */
  formDataConfig: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['text', 'textarea', 'checkbox']).isRequired,
      label: PropTypes.string.isRequired,
      required: PropTypes.bool,
      minLength: PropTypes.number,
      description: PropTypes.string,
      errorMessage: PropTypes.string,
      rows: PropTypes.number
    })
  ).isRequired,
  /**
   * Начальные данные для формы
   */
  initialData: PropTypes.object,
  /**
   * Массив ошибок из бэка.
   * Вида [{attr: 'title', error: 'Поле должно быть заполнено', code: 720}]
   */
  errors: PropTypes.array
};

DynamicForm.defaultProps = {
  initialData: {},
  errors: []
};

export default DynamicForm;
