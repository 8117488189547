import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';

/**
 * Поля для ввода пароля и его повтор. Применяется для форм установки нового пароля
 * при восстановлении, регистрации, смене пароля и др.
 * @param resultCallback - callback функция вызывающаяся при установке пароля и его повторе
 * @returns {JSX.Element}
 * @constructor
 */
const NewPasswordFields = ({ resultCallback }) => {
  const [state, setState] = useState({
    errors: [],
    password: '',
    confirmPassword: '',
    isValidated: {
      password: false,
      confirmPassword: false
    }
  });

  const handleFieldChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      isValidated: {
        password: state.isValidated.password || e.target.name === 'password',
        confirmPassword:
          state.isValidated.confirmPassword ||
          e.target.name === 'confirmPassword'
      }
    });
  };

  useEffect(() => {
    if (state.password === state.confirmPassword && state.password.length > 6) {
      resultCallback(state.password);
    } else {
      resultCallback('');
    }
  }, [state.password, state.confirmPassword]);

  return (
    <>
      <InputGroup className="mb-3" hasValidation>
        <Form.Label className="col-12">Пароль</Form.Label>
        <Form.Control
          placeholder={'Введите новый пароль'}
          value={state.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
          autoComplete="off"
          isInvalid={state.password.length < 6 && state.isValidated.password}
        />
        <Form.Control.Feedback type="invalid">
          {state.password.length < 6 && 'Пароль не менее 6 символов'}
        </Form.Control.Feedback>
      </InputGroup>

      <Form.Group className="mb-3">
        <Form.Label className="col-12">Повтор пароля</Form.Label>
        <Form.Control
          placeholder={'Повторите ваш пароль'}
          value={state.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
          autoComplete="off"
          isInvalid={
            state.confirmPassword !== state.password &&
            state.isValidated.confirmPassword
          }
        />
        <Form.Control.Feedback type="invalid">
          {state.password !== state.confirmPassword &&
            'Пароль и его повтор должны совпадать'}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

NewPasswordFields.propTypes = {
  resultCallback: PropTypes.func
};

NewPasswordFields.defaultProps = { resultCallback: function () {} };

export default NewPasswordFields;
