import React from 'react';
import { Button, Col, Row, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MyHost = ({ host, onEdit, onDelete, isLast, onActivate, onDisable }) => {
  const { host: hostAddress, is_active, auth_suc_redirect } = host;

  return (
    <Row
      className={classNames(
        'align-items-center py-2 border-200' + (isLast ? '' : ' border-bottom')
      )}
    >
      <Col xs={10} md={11}>
        <Row>
          <Col xs={5} md={3} className="py-1 position-relative">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon="circle"
                className={`me-3 text-${is_active ? 'success' : 'danger'}`}
              />
              <span className="text-800">{hostAddress}</span>
            </div>
          </Col>
          <Col xs={7} md={9}>
            <span className="fs--1">{auth_suc_redirect}</span>
          </Col>
        </Row>
      </Col>
      <Col xs={2} md={1} className="d-flex flex-end-center pb-1 pt-1">
        <Dropdown className="position-absolute">
          <Dropdown.Toggle
            as={Button}
            variant="falcon-default"
            size="sm"
            className="dropdown-caret-none"
          >
            <FontAwesomeIcon icon="ellipsis-h" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                onEdit(host);
              }}
            >
              Изменить
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onDisable(host);
                console.log('Отключить');
              }}
            >
              Отключить
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onActivate(host);
                console.log('Активировать');
              }}
            >
              Активировать
            </Dropdown.Item>
            <Dropdown.Divider></Dropdown.Divider>
            <Dropdown.Item
              onClick={() => {
                onDelete(host);
              }}
              className="text-danger"
            >
              Удалить
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

MyHost.propTypes = {
  /**
   * Объект, представляющий информацию о хосте.
   */
  host: PropTypes.shape({
    /**
     * Уникальный идентификатор хоста.
     */
    id: PropTypes.number.isRequired,

    /**
     * Имя хоста.
     */
    host: PropTypes.string.isRequired,

    /**
     * Флаг, указывающий, активен ли хост.
     */
    is_active: PropTypes.bool,

    /**
     * URL для перенаправления после успешной аутентификации.
     */
    auth_suc_redirect: PropTypes.string,

    /**
     * Флаг, указывающий, нужно ли использовать перезагрузку.
     */
    use_reload: PropTypes.bool
  }),

  /**
   * Функция, вызываемая при редактировании хоста.
   */
  onEdit: PropTypes.func,

  /**
   * Функция, вызываемая при удалении хоста.
   */
  onDelete: PropTypes.func,

  /**
   * Функция, вызываемая при активации хоста.
   */
  onActivate: PropTypes.func,

  /**
   * Функция, вызываемая при деактивации хоста.
   */
  onDisable: PropTypes.func,

  /**
   * Флаг, указывающий, является ли этот хост последним в списке.
   */
  isLast: PropTypes.bool
};

export default MyHost;
