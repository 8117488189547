import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../features/user/userSlice';

const RegistrationForm = ({ hasLabel }) => {
  let isLoading = useSelector(state => state.user.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    login: '',
    password: '',
    confirmPassword: '',
    isAccepted: false,
    errors: {},
    isValidated: false
  });

  const registerUser = () => {
    setFormData({
      ...formData,
      ['errors']: {}
    });

    return dispatch(
      register({
        email: formData.email,
        login: formData.login,
        name: formData.name,
        password: formData.password
      })
    );
  };

  // Получить объект ошибок
  const getErrors = errors => {
    if (!errors?.length) {
      return {};
    }

    let errObj = {};

    for (let i = 0; i < errors.length; i++) {
      if (!errObj[errors[i].Attr]) {
        errObj[errors[i].Attr] = '';
      }

      let attrErrors = errors.filter(item => item.Attr === errors[i].Attr);

      let errorList = [];
      attrErrors.map(item => {
        errorList.push(item.Error);
      });

      errObj[errors[i].Attr] = errorList.join(`\n`);
    }
    return errObj;
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.currentTarget;
    setFormData({
      ...formData,
      ['isValidated']: true,
      ['errors']: {}
    });

    if (form.checkValidity() === true) {
      registerUser().then(answer => {
        if (answer?.payload?.error !== null) {
          toast.error(`Ошибка: ${answer?.payload?.error}`, {
            theme: 'colored'
          });

          if (answer.payload.model_errors?.length) {
            let errors = getErrors(answer.payload.model_errors[0]);
            setFormData({
              ...formData,
              ['isValidated']: true,
              ['errors']: errors
            });
          }
        } else {
          toast.success(
            `Вы успешно зарегистрированы под именем ${formData.login}`,
            {
              theme: 'colored'
            }
          );

          setTimeout(() => {
            navigate('/authentication/confirm-mail', {
              state: { email: formData.email }
            });
          });
        }
      });
    }
  };

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleFieldChange = e => {
    let newErrs = JSON.parse(JSON.stringify(formData.errors));
    let errName = capitalizeFirstLetter(e.target.name);
    if (formData?.errors[errName]) {
      newErrs[errName] = null;
      setFormData({
        ...formData,
        ['errors']: newErrs
      });
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      errors: newErrs
    });
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <InputGroup className="mb-3" hasValidation>
        {hasLabel && <Form.Label>Имя</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Имя' : ''}
          value={formData.name}
          name="name"
          required
          isInvalid={
            (!formData.name && formData.isValidated) || formData?.errors?.Name
          }
          onChange={handleFieldChange}
          type="text"
        />
        <Form.Control.Feedback type="invalid">
          {formData?.errors?.Name || 'Пожалуйста укажите имя'}
        </Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mb-3" hasValidation>
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email' : ''}
          value={formData.email}
          required
          name="email"
          onChange={handleFieldChange}
          type="text"
          isInvalid={
            (!formData.email && formData.isValidated) || formData?.errors?.Email
          }
        />
        <Form.Control.Feedback type="invalid">
          {formData?.errors?.Email || 'Пожалуйста укажите email'}
        </Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mb-3" hasValidation>
        {hasLabel && <Form.Label>Логин</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Логин' : ''}
          value={formData.login}
          name="login"
          onChange={handleFieldChange}
          type="text"
          required
          isInvalid={
            (!formData.login && formData.isValidated) || formData?.errors?.Login
          }
        />
        <Form.Control.Feedback type="invalid">
          {formData?.errors?.Login || 'Пожалуйста укажите логин'}
        </Form.Control.Feedback>
      </InputGroup>
      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          <InputGroup hasValidation>
            {hasLabel && <Form.Label>Пароль</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Пароль' : ''}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type="password"
              required
              isInvalid={
                (!formData.password ||
                  formData.password !== formData.confirmPassword) &&
                formData.isValidated
              }
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <InputGroup hasValidation>
            {hasLabel && <Form.Label>Повтор пароля</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Повторите пароль' : ''}
              value={formData.confirmPassword}
              name="confirmPassword"
              required
              onChange={handleFieldChange}
              type="password"
              isInvalid={
                (!formData.confirmPassword ||
                  formData.password !== formData.confirmPassword) &&
                formData.isValidated
              }
            />
          </InputGroup>
        </Form.Group>
      </Row>
      <InputGroup hasValidation>
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            required
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
            isInvalid={!formData.isAccepted && formData.isValidated}
          />
          <Form.Check.Label className="form-label">
            Я прочитал(а) и принимаю{' '}
            <Link to="#!">
              правила сервиса, термины, определения и политику
              конфиденциальности
            </Link>
          </Form.Check.Label>
        </Form.Check>
      </InputGroup>
      <Form.Group>
        <Button className="w-100" type="submit" disabled={isLoading}>
          Регистрация
        </Button>
      </Form.Group>
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
