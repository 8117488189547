import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../api';

export const createMyHosts = createAsyncThunk(
  'host/create',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.post('/api/v1/myhost', [formValue]);
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);
export const updateMyHosts = createAsyncThunk(
  'host/update',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.put('/api/v1/myhost', [formValue]);
      return response.data;
    } catch (err) {
      console.log('err.response', err.response);
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);
export const patchMyHosts = createAsyncThunk(
  'host/patch',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.patch('/api/v1/myhost', [formValue]);
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);

export const deleteMyHosts = createAsyncThunk(
  'host/delete',
  async (formValue, { rejectWithValue }) => {
    try {
      const response = await API.delete('/api/v1/myhost', {
        data: [formValue]
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);

export const findMyHosts = createAsyncThunk(
  'host/findMyHosts',
  async (params, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams({
        'order[]': 'id',
        'order_direction[]': 'desc',
        ...(params.application_id && { application_id: params.application_id })
      });

      const response = await API.get(
        `/api/v1/myhost?${queryParams.toString()}`
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: 'Системная ошибка на сервере. Мы уже занимаемся.'
      });
    }
  }
);

const myHostSlice = createSlice({
  name: 'myHost',
  initialState: {
    myhosts: [],
    error: '',
    loading: false
  },
  extraReducers: {
    [findMyHosts.pending]: state => {
      state.loading = true;
    },
    [findMyHosts.pending]: state => {
      state.loading = true;
    },
    [findMyHosts.fulfilled]: (state, action) => {
      state.loading = false;

      if (!action?.payload?.data?.length) {
        return;
      }

      state.myhosts = action?.payload?.data || [];
    },
    [findMyHosts.rejected]: (state, action) => {
      state.loading = false;
      if (action?.payload?.message) {
        state.error = action?.payload.message;
      } else {
        state.error = 'Системная ошибка';
      }
    },
    [createMyHosts.rejected]: (state, action) => {
      state.loading = false;
      if (action?.payload?.message) {
        state.error = action?.payload.message;
      } else {
        state.error = 'Системная ошибка';
      }
    },
    [updateMyHosts.rejected]: (state, action) => {
      state.loading = false;
      if (action?.payload?.message) {
        state.error = action?.payload.message;
      } else {
        state.error = 'Системная ошибка';
      }
    }
  }
});

export default myHostSlice.reducer;
