import { Button, Col, Form, InputGroup, Offcanvas, Row } from 'react-bootstrap';
import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * `MyAppForm` Компонент формы добавления и изменения основных параметров приложения
 *
 * @component
 * @example
 *   // Example usage of MyComponent
 *   <MyAppForm
 *      isShow={isShowAddForm}
 *      onHide={hideFormHandler}
 *      onSave={saveHandler}
 *      appData={appData}
 *      title={
 *        appData?.id > 0 ? 'Изменить приложение' : 'Добавить приложение'
 *      }
 *   />
 */
const MyAppForm = ({ isShow, onHide, onSave, title, appData, errors }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    titleTouched: false,
    descriptionTouched: false,
    titleError: '',
    descriptionError: ''
  });

  useEffect(() => {
    if (appData) {
      setFormData({
        ...formData,
        title: appData.title || '',
        description: appData.description || ''
      });
    }
  }, [appData, isShow]);

  useEffect(() => {
    errors.forEach(item => {
      console.log('description error', item);
      if (item?.attr === 'title') {
        setFormData({
          ...formData,
          titleError: item.error
        });
      }
      if (item?.attr === 'description') {
        setFormData({
          ...formData,
          descriptionError: item.error
        });
      }
    });
  }, [errors]);

  const handleFieldChange = (e, index = null) => {
    let name = e.target.name;
    let val = e.target.value;
    if (name === 'host') {
      let newHosts = [...formData.hosts];
      newHosts[index] = val;

      let touched = formData.hostsTouched;
      touched[index] = val?.length > 1 || formData.hostsTouched[index];

      setFormData({
        ...formData,
        hosts: newHosts,
        hostsTouched: touched
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: val,
      [name + 'Touched']: val?.length > 1 || formData[name + 'Touched'],
      [name + 'Error']: val?.length > 1 || formData[name + 'Error']
    });
  };
  const clearFormData = () => {
    setFormData({
      ...formData,
      titleTouched: false,
      descriptionTouched: false
    });
  };
  const cancelForm = () => {
    onHide();
    clearFormData();
  };

  const isValidField = field => {
    let invalidFields = [];
    if (formData.title?.length < 2) {
      invalidFields.push('title');
    }

    if (formData.description?.length < 1) {
      invalidFields.push('description');
    }

    if (field) {
      return invalidFields.indexOf(field) === -1;
    }
    return invalidFields.length < 1;
  };

  const saveApp = () => {
    setFormData({
      ...formData,
      titleTouched: true,
      descriptionTouched: true
    });

    if (isValidField()) {
      let res =
        {
          id: appData.id,
          title: appData.title,
          description: appData.description
        } || {};

      res.title = formData.title;
      res.description = formData.description;
      onSave(res);
      clearFormData();
    }
  };

  return (
    <>
      <Offcanvas show={isShow} onHide={cancelForm} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="g-3 mb-3">
            <Col xs={12}>
              <InputGroup className="mb-3" hasValidation>
                <Form.Control
                  placeholder={'Название приложения'}
                  value={formData.title}
                  name="title"
                  onChange={handleFieldChange}
                  type="text"
                  required
                  isInvalid={
                    (!isValidField('title') && formData.titleTouched) ||
                    formData.titleError?.length
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formData.title.length < 2 && 'Укажите название приложение'}
                  {formData.titleError}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col xs={12}>
              <InputGroup className="mb-3" hasValidation>
                <Form.Control
                  placeholder={'Краткое описание'}
                  value={formData.description}
                  name="description"
                  as="textarea"
                  rows={3}
                  onChange={handleFieldChange}
                  type="text"
                  required
                  isInvalid={
                    (!isValidField('description') &&
                      formData.descriptionTouched) ||
                    formData.descriptionError?.length
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {!isValidField('description') &&
                    'Укажите описание приложение'}
                  {formData.descriptionError}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button variant="success" className="me-2 mb-1" onClick={saveApp}>
                Сохранить
              </Button>
              <Button
                variant="secondary"
                className="me-2 mb-1"
                onClick={cancelForm}
              >
                Отмена
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

MyAppForm.propTypes = {
  /**
   * Заголовок в окне формы
   */
  title: PropTypes.string,
  /**
   * Флаг, определяющий показывать модальное окно или нет
   */
  isShow: PropTypes.bool,
  /**
   * Событие, которые будет вызвано,
   * когда форма закрывается
   */
  onHide: PropTypes.func,
  /**
   * Событие, вызываемое при попытке успешного сохранения
   * валидной формы
   */
  onSave: PropTypes.func,
  /**
   * Объект, содержащий приложение.
   * Редактируемые поля title, description.
   * Другие поля возвращаются без изменений
   */
  appData: PropTypes.object,
  /**
   * Массив ошибок из бэка.
   * Вида [{attr: 'title', error: 'Поле должно быть заполнено', code: 720}]
   */
  errors: PropTypes.array
};

export default MyAppForm;
