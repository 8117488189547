import { React } from 'react';
import { Button, Col, Row, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Flex from '../../common/Flex';
import PropTypes from 'prop-types';

const MyApp = ({ app, onEdit, onDelete, isLast, onActivate, onDisable }) => {
  const { title, description, is_active, id } = app;

  return (
    <Row
      className={classNames(
        'align-items-center py-2 border-200' + (isLast ? '' : ' border-bottom')
      )}
    >
      <Col xs={10} md={11}>
        <Row>
          <Col xs={5} md={3} className="py-1 position-relative">
            <Flex tag="h6" className="mb-0">
              <FontAwesomeIcon
                icon="circle"
                className={`me-3 text-${is_active ? 'success' : 'danger'}`}
              />
              <Link
                to={'/apps/details/' + id}
                className="text-800 stretched-link"
              >
                {title}
              </Link>
            </Flex>
          </Col>
          <Col xs={7} md={9}>
            <span className="fs--1">{description}</span>
          </Col>
        </Row>
      </Col>
      <Col xs={2} md={1} className="d-flex flex-end-center pb-1 pt-1">
        <Dropdown className="position-absolute">
          <Dropdown.Toggle
            as={Button}
            variant="falcon-default"
            size="sm"
            className="dropdown-caret-none"
          >
            <FontAwesomeIcon icon="ellipsis-h" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              onClick={() => {
                onEdit(app);
              }}
            >
              Изменить
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              onClick={() => {
                onDisable(app);
                console.log('Отключить');
              }}
            >
              Отключить
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              onClick={() => {
                onActivate(app);
                console.log('Активировать');
              }}
            >
              Активировать
            </Dropdown.Item>
            <Dropdown.Divider></Dropdown.Divider>
            <Dropdown.Item
              as={Link}
              onClick={() => {
                onDelete(app);
                console.log('Удалить');
              }}
              className="text-danger"
            >
              Удалить
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

MyApp.propTypes = {
  /**
   * Приложение, которое обслуживается в проекте
   */
  app: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    is_active: PropTypes.bool
  }),
  /**
   * Колбек редактирования. Вызывается при нажатии кнопок редактирования
   */
  onEdit: PropTypes.func,
  /**
   * Колбек удаления. Вызывается при нажатии кнопок удаления
   */
  onDelete: PropTypes.func,
  /**
   * Колбек активации. Вызывается при нажатии кнопок "Активировать"
   */
  onActivate: PropTypes.func,
  /**
   * Колбек отключения. Вызывается при нажатии кнопок "Отключить"
   */
  onDisable: PropTypes.func,
  /**
   * Последняя строчка в списке ? Для последней не отображать нижнюю границу.
   */
  isLast: PropTypes.bool
};

export default MyApp;
