import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
const ModalPrompt = ({
  isShow,
  title,
  yesAction,
  noAction,
  yesVariant,
  noVariant,
  yesText,
  noText,
  // eslint-disable-next-line react/prop-types
  children
}) => {
  const [modalShow, setModalShow] = useState(true);
  const [yesBtnText, setYesBtnText] = useState('Ok');
  const [yesBtnVariant, setYesBtnVariant] = useState('primary');
  const [noBtnText, setNoBtnText] = useState('Отмена');
  const [noBtnVariant, setNoBtnVariant] = useState('secondary');
  const [cardTitle, setCardTitle] = useState('Внимание');

  const setData = () => {
    setModalShow(isShow);
    setYesBtnText(yesText || yesBtnText);
    setNoBtnText(noText || noBtnText);
    setYesBtnVariant(yesVariant || yesBtnVariant);
    setNoBtnVariant(noVariant || noBtnVariant);
    setCardTitle(title.length ? title : cardTitle);
  };

  useEffect(() => {
    setData();
  }, [
    isShow,
    title,
    yesAction,
    noAction,
    yesVariant,
    noVariant,
    yesText,
    noText
  ]);

  if (!isShow) {
    return <></>;
  }

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => {
          noAction();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {cardTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button
            variant={yesBtnVariant}
            onClick={() => {
              yesAction();
            }}
          >
            {yesBtnText}
          </Button>
          <Button
            variant={noBtnVariant}
            onClick={() => {
              noAction();
            }}
          >
            {noBtnText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ModalPrompt.propTypes = {
  /**
   * Показывать prompt или нет
   */
  isShow: PropTypes.bool,
  /**
   * Заголовок окна
   */
  title: PropTypes.string,
  /**
   * События по кнопке ОК
   */
  yesAction: PropTypes.func,
  /**
   * Текст кнопки ОК
   */
  yesText: PropTypes.string,
  /**
   * Вариант отображения Yes кнопки: primary,secondary,success,info,warning,danger,light,dark,link
   */
  yesVariant: PropTypes.string,
  /**
   * Вариант отображения No кнопки: primary,secondary,success,info,warning,danger,light,dark,link
   */
  noVariant: PropTypes.string,
  /**
   * События кнопки Отмена
   */
  noAction: PropTypes.func,
  /**
   * Текст кнопки отмена
   */
  noText: PropTypes.string
};

export default ModalPrompt;
