import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { checkVerification } from '../../features/user/userSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const VerifyForm = ({ resultCallback }) => {
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code') || '';
  const email = queryParams.get('email') || '';

  // State
  const [state, setState] = useState({
    code: code,
    email: email,
    emailErrorMessage: '',
    codeErrorMessage: ''
  });

  const verify = () => {
    return dispatch(
      checkVerification({
        code: state.code,
        email: state.email
      })
    );
  };

  const handleFieldChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    setState({
      ...state,
      emailErrorMessage: '',
      codeErrorMessage: ''
    });

    if (form.checkValidity() === true) {
      verify().then(data => {
        if (!data?.payload || data?.payload?.error) {
          toast.error(data?.payload?.error || 'Ошибка подтверждения Email');
        } else {
          resultCallback(true);
        }
      });
    }
  };

  return (
    <>
      <h3>Код подтверждение Email</h3>
      <Form onSubmit={handleSubmit} noValidate>
        <InputGroup className="mb-3" hasValidation>
          <Form.Control
            style={{ textAlign: 'center' }}
            placeholder="Email"
            value={state.email}
            name="email"
            onChange={handleFieldChange}
            type="text"
            required
            isInvalid={!state.email || state.emailErrorMessage}
          />
          <Form.Control.Feedback type="invalid">
            {state.code === '' && 'Пожалуйста укажите код Email'}
            {state.emailErrorMessage}
          </Form.Control.Feedback>
        </InputGroup>

        <InputGroup className="mb-3" hasValidation>
          <Form.Control
            style={{ textAlign: 'center' }}
            placeholder="Код подтверждения"
            value={state.code}
            name="code"
            onChange={handleFieldChange}
            type="text"
            required
            isInvalid={!state.code || state.codeErrorMessage}
          />
          <Form.Control.Feedback type="invalid">
            {state.code === '' && 'Пожалуйста укажите код подтверждения'}
            {state.codeErrorMessage}
          </Form.Control.Feedback>
        </InputGroup>

        <Form.Group>
          <Button type="submit" color="primary" className="mt-3 w-100">
            Подтвердить email
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

VerifyForm.propTypes = {
  resultCallback: PropTypes.func
};

VerifyForm.defaultProps = { resultCallback: function () {} };
export default VerifyForm;
