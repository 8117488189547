import React from 'react';
import VerifyMailContent from '../VerifyMailContent';

const ConfirmMail = () => {
  return (
    <div className="text-center">
      <VerifyMailContent />
    </div>
  );
};

export default ConfirmMail;
