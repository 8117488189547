// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import { useDispatch } from 'react-redux';

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const logoutHandler = e => {
    e.preventDefault();

    dispatch({ type: 'logout' });
    navigator('/');
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={team3} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/*<Dropdown.Item className="fw-bold text-warning" href="#!">*/}
          {/*  <FontAwesomeIcon icon="crown" className="me-1" />*/}
          {/*  <span>Подписка Pro</span>*/}
          {/*</Dropdown.Item>*/}
          {/*<Dropdown.Divider />*/}
          {/*<Dropdown.Item as={Link} to="/user/profile">*/}
          {/*  Профиль*/}
          {/*</Dropdown.Item>*/}
          {/*<Dropdown.Item as={Link} to="/user/settings">*/}
          {/*  Настройки*/}
          {/*</Dropdown.Item>*/}
          <Dropdown.Item as={Link} onClick={logoutHandler}>
            Выход
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
