import React, { useState } from 'react';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import VerifyForm from './VerifyForm';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VerifyMailContent = () => {
  // State
  const [state, setState] = useState({
    isVerified: false
  });

  const handleIsVerify = e => {
    setState({
      ...state,
      isVerified: e
    });
  };

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={envelope}
        alt="sent"
        width={100}
      />

      {!state.isVerified && <VerifyForm resultCallback={handleIsVerify} />}

      {state.isVerified && (
        <>
          <h3>Спасибо, Email подтвержден</h3>
          <Button as={Link} color="primary" size="sm" className="mt-3" to={`/`}>
            <FontAwesomeIcon
              icon="chevron-left"
              transform="shrink-4 down-1"
              className="me-1"
            />
            На страницу входа
          </Button>
        </>
      )}
    </>
  );
};

export default VerifyMailContent;
