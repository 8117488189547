import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { sendEmailVerification } from '../../features/user/userSlice';
import { useDispatch } from 'react-redux';

const ConfirmMailContent = ({ email, titleTag: TitleTag, repeatVerify }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (repeatVerify) {
      clearTimeout(window.emailVerify);
      window.emailVerify = setTimeout(() => {
        dispatch(
          sendEmailVerification({
            email: email
          })
        );
      }, 200);
    }
  }, []);

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={envelope}
        alt="sent"
        width={100}
      />
      <TitleTag>Пожалуйста проверьте Email!</TitleTag>
      <p>
        Email сообщение было отправлено на почту <strong>{email}</strong>.
        Пожалуйста, нажмите на ссылку в сообщение, что бы активировать вашу
        учетную запись.
      </p>
      <Button as={Link} color="primary" size="sm" className="mt-3" to={`/`}>
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        На страницу входа
      </Button>
    </>
  );
};

ConfirmMailContent.propTypes = {
  email: PropTypes.string.isRequired,
  titleTag: PropTypes.string,
  repeatVerify: PropTypes.bool
};

ConfirmMailContent.defaultProps = { titleTag: 'h4' };

export default ConfirmMailContent;
