import { React, useEffect, useState } from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import IconButton from '../../common/IconButton';
import {
  deleteMyApps,
  findMyApps,
  createMyApps,
  updateMyApps,
  patchMyApps
} from '../../../features/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import MyAppForm from './MyAppForm';
import MyApp from './MyApp';
import { toast } from 'react-toastify';
import ModalPrompt from '../../modal/ModalPrompt';

const MyApps = () => {
  const [isShowAddForm, setIsShowAddForm] = useState(false);
  const [apps, setApps] = useState([]);
  const [appData, setAppdata] = useState({});
  const [errors, setErrors] = useState([]);
  const [isShowPrompt, setIsShowPrompt] = useState(false);
  const [appForDelete, setAppForDelete] = useState(null);

  let isLoading = useSelector(state => state.myApp.loading);
  const [loading, setLoading] = useState(isLoading);
  let timer = null;

  useEffect(() => {
    clearTimeout(timer);
    setLoading(isLoading);
  }, [isLoading]);

  const dispatch = useDispatch();
  const addApp = () => {
    setAppdata({});
    setIsShowAddForm(true);
  };
  const hideFormHandler = () => {
    setIsShowAddForm(false);
  };

  const deleteHandler = app => {
    setIsShowPrompt(true);
    setAppForDelete(app);
  };

  const changeActivation = (app, isActive) => {
    let appData = {
      id: app.id,
      is_active: isActive
    };

    patchApp(appData).then(answer => {
      if (answer?.payload?.error !== null) {
        toast.error(`Ошибка: ${answer?.payload?.error}`, {
          theme: 'colored'
        });
        if (answer?.payload?.model_errors?.length) {
          setErrors(answer.payload.model_errors[0]);
        }
      } else {
        loadMyApps();
      }
    });
  };
  const activateHandler = app => {
    changeActivation(app, true);
  };

  const disableHandler = app => {
    changeActivation(app, false);
  };

  const saveHandler = data => {
    let response;
    if (data?.id > 0) {
      response = updateApp(data);
    } else {
      response = createApp(data);
    }
    response.then(answer => {
      if (answer?.payload?.error !== null) {
        toast.error(`Ошибка: ${answer?.payload?.error}`, {
          theme: 'colored'
        });
        if (answer?.payload?.model_errors?.length) {
          setErrors(answer.payload.model_errors[0]);
        }
      } else {
        setIsShowAddForm(false);
        loadMyApps();
      }
    });
  };

  const editHandler = editApp => {
    setAppdata(editApp);
    setIsShowAddForm(true);
  };
  const loadMyApps = () => {
    return dispatch(findMyApps()).then(data => {
      setApps(data?.payload?.data || []);
    });
  };
  const createApp = data => {
    return dispatch(createMyApps(data)).then(resp => {
      return resp;
    });
  };

  const updateApp = data => {
    return dispatch(updateMyApps(data)).then(resp => {
      return resp;
    });
  };

  const patchApp = data => {
    return dispatch(patchMyApps(data)).then(resp => {
      return resp;
    });
  };

  const deleteApp = data => {
    return dispatch(deleteMyApps(data)).then(resp => {
      setIsShowPrompt(false);
      setAppForDelete({});
      loadMyApps();
      return resp;
    });
  };

  const noHandler = () => {
    setIsShowPrompt(false);
  };
  const yesHandler = () => {
    deleteApp(appForDelete);
  };

  useEffect(() => {
    loadMyApps();
  }, []);

  return (
    <>
      <Card className="shadow-none shadow-show-xl">
        <Card.Header className="d-flex flex-between-center bg-light">
          <h5 className="mb-0">Мои приложения</h5>
          <IconButton
            disabled={isShowAddForm}
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
            onClick={addApp}
          >
            <span>Добавить</span>
          </IconButton>
        </Card.Header>
        <Card.Body className="contact-details-todo-list scrollbar h-100">
          <MyAppForm
            isShow={isShowAddForm}
            onHide={hideFormHandler}
            onSave={saveHandler}
            appData={appData}
            errors={errors}
            title={
              appData?.id > 0 ? 'Изменить приложение' : 'Добавить приложение'
            }
          />
          {apps.length > 0 &&
            apps.map((item, k) => {
              return (
                <MyApp
                  isLast={k === apps.length - 1}
                  key={item.id}
                  onEdit={() => {
                    editHandler(item);
                  }}
                  onDelete={() => {
                    deleteHandler(item);
                  }}
                  onActivate={() => {
                    activateHandler(item);
                  }}
                  onDisable={() => {
                    disableHandler(item);
                  }}
                  app={item}
                />
              );
            })}
          {loading && (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} size="xs" />
            </Placeholder>
          )}
          <ModalPrompt
            yesVariant={'danger'}
            title={'Удалить приложение ?'}
            isShow={isShowPrompt}
            yesAction={yesHandler}
            noAction={noHandler}
          >
            <h5>{appForDelete?.title}</h5>
            <p>
              Удаление приложение приведет к невозможности авторизации
              пользователей, использующих это приложение. Операцию удаления
              невозможно отменить. Вы действительно хотите удалить приложение ?
            </p>
          </ModalPrompt>
        </Card.Body>
        <Card.Footer className="border-top border-200 text-xl-center p-0">
          <div className={'py-x1 py-xl-2'}>&nbsp;</div>
        </Card.Footer>
      </Card>
    </>
  );
};

MyApps.propTypes = {};

export default MyApps;
