export const dashboardRoutes = {
  label: 'Центр управления',
  labelDisable: true,
  children: [
    {
      name: 'Центр управления',
      to: '/apps',
      active: true,
      icon: 'chart-pie'
    }
  ]
};

export const aboutServiceRoutes = {
  label: 'О сервисе',
  children: [
    {
      name: 'Функции сервиса',
      to: '/about',
      active: true,
      icon: 'info-circle'
    },
    {
      name: 'Установка',
      to: '/about',
      active: true,
      icon: 'info-circle'
    }
  ]
};

export const documentationRoutes = {
  label: 'Документация',
  children: [
    {
      name: 'Начало работы',
      icon: 'rocket',
      to: '/documentation/getting-started',
      active: true
    },
    {
      name: 'Кастомизация',
      active: true,
      icon: 'wrench',
      children: [
        {
          name: 'Configuration',
          to: '/documentation/configuration',
          active: true
        },
        {
          name: 'Styling',
          to: '/documentation/styling',
          active: true
        },
        {
          name: 'Dark mode',
          to: '/documentation/dark-mode',
          active: true
        },
        {
          name: 'Plugin',
          to: '/documentation/plugin',
          active: true
        }
      ]
    },
    {
      name: 'Faq',
      icon: 'question-circle',
      to: '/documentation/faq',
      active: true
    }
  ]
};

export default [dashboardRoutes, aboutServiceRoutes, documentationRoutes];
